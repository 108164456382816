import React from 'react'
import { useField, ErrorMessage } from 'formik'
import { TextField } from '@mui/material'

const InputField = ({ id, label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <TextField
      autoComplete="off"
      error={meta.touched && meta.error ? true : false}
      helperText={
        meta.touched && meta.error && <ErrorMessage name={props.name} />
      }
      id={id}
      label={label}
      variant="outlined"
      {...field}
      {...props}
    />
  )
}

export default InputField
