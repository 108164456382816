import jwt_decode from 'jwt-decode'

const AccessControl = ({ children, access = false }) => {
  const decoded = jwt_decode(localStorage.getItem('token'))
  const isAdmin = decoded?.admin === 'True' ? true : false

  if (isAdmin || access) {
    return children
  } else {
    return false
  }
}

export default AccessControl
