import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'
import {
  BadgeRounded,
  PersonRounded,
  MailRounded,
  LocalPhoneRounded,
} from '@mui/icons-material'
import { DialogTransition } from '../../components/DialogTransition'

const UserProfileDialog = ({ data, isLoading, open, handleClose }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      keepMounted={false}
      TransitionComponent={DialogTransition}
      disableEscapeKeyDown
      onClose={handleClose}
    >
      <DialogTitle>Profile details</DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <List dense disablePadding>
          <Items
            loading={isLoading}
            icon={<BadgeRounded />}
            title="Name"
            description={data?.name}
          />
          <Items
            loading={isLoading}
            icon={<PersonRounded />}
            title="Username"
            description={data?.username}
          />
          <Items
            loading={isLoading}
            icon={<MailRounded />}
            title="Email address"
            description={data?.email}
          />
          <Items
            loading={isLoading}
            icon={<LocalPhoneRounded />}
            title="Mobile number"
            description={data?.mobile}
          />
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default UserProfileDialog

const Items = ({ loading, icon, title, description }) => {
  return (
    <ListItem divider>
      <ListItemAvatar>
        {loading ? (
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        ) : (
          <Avatar>{icon}</Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography fontWeight={400} fontSize={12} color="#bdbdbd">
            {loading ? <Skeleton width={100} /> : title}
          </Typography>
        }
        secondary={
          <Typography
            component="div"
            fontWeight={600}
            fontSize={16}
            overflow="hidden"
            textOverflow="ellipsis"
            color="#000"
          >
            {loading ? <Skeleton width={250} /> : description}
          </Typography>
        }
      />
    </ListItem>
  )
}
