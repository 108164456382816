import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { DialogTransition } from '../../components/DialogTransition'
import InputField from '../../components/InputField'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axiosInstance from '../../helpers/axiosInstance'
import toast from 'react-hot-toast'

const SelfTopUpDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      keepMounted={false}
      TransitionComponent={DialogTransition}
      disableEscapeKeyDown
      onClose={handleClose}
    >
      <Formik
        initialValues={{
          topup: '',
        }}
        validationSchema={Yup.object({
          topup: Yup.string()
            .matches(/^\d+$/, 'Topup amount should have digits only')
            .min(2)
            .required()
            .label('Topup amount'),
        })}
        onSubmit={async (values, actions) => {
          await axiosInstance({
            method: 'PUT',
            url: `/plan/admintopup/${values.topup}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
              actions.resetForm()
              handleClose()
              toast.success(`top up recharge successfully`)
            })
            .catch((error) => {
              toast.error(error.response.data.error)
            })
        }}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <DialogTitle>Topup Recharge</DialogTitle>
            <DialogContent dividers>
              <InputField fullWidth name="topup" label="Topup amount" />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>close</Button>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                disabled={!(dirty && isValid) || isSubmitting}
              >
                topup Recharge
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default SelfTopUpDialog
