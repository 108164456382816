import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Box, CircularProgress } from '@mui/material'
import Layout from './layout'

const Login = lazy(() => import('./pages/Login'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Users = lazy(() => import('./pages/Users'))
const BalanceLedger = lazy(() => import('./pages/BalanceLedger'))
const Plans = lazy(() => import('./pages/Plans'))
const UserPlan = lazy(() => import('./pages/UserPlan'))
const Jobs = lazy(() => import('./pages/Jobs'))
const Banks = lazy(() => import('./pages/Banks'))
const TorrentPower = lazy(() => import('./pages/TorrentPower'))
const RefundRequests = lazy(() => import('./pages/RefundRequests'))

const Routing = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Toaster position="top-right" toastOptions={{}} />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          }
        />

        <Route path="/" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="balanceledger" element={<BalanceLedger />} />
          <Route path="plans" element={<Plans />} />
          <Route path="topup/request" element={<UserPlan />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="banks" element={<Banks />} />
          <Route path="torrentpower" element={<TorrentPower />} />
          <Route path="refundrequest" element={<RefundRequests />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default Routing

const Loading = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  )
}
